import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/michal/work/bite-api/frontend/dashboard/src/components/layout/dashboard.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Knowledge Graph`}</h1>
    <p>{`The API stores information about foods, the nutrition facts, and relationships between the foods
in the knowledge base. `}</p>
    <h2>{`Concepts`}</h2>
    <h3>{`Item`}</h3>
    <p>{`Items represent food items like a tomato. Subtypes include Products, Recipes, Restaurant Dishes.
Items are related to each other by a parent and child relationship. For instance, brown rice is a
type of rice. Items can be composed of other items which allows for the creation of variations of
an item like a baked potato with sour cream or without. They are associated with nutrition facts. `}</p>
    <h3>{`Unit`}</h3>
    <p>{`A unit is associated with a Nutrition Facts serving. Common units include measurements like
cups, tablespoons, fluid ounces, grams, etc.. Also, units can include things which are countable
or the prepared item like cookie, chips, pretzel, chicken strip, or potato wedge.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      